
const initialState = {
  base: {
  }
}

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    default: {
      return state
    }
  }
}

export default mainReducer
